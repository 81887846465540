import React, { useState } from 'react';
import './App.css';

function App() {
  const [query, setQuery] = useState('');
  const [verse, setVerse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function getVerse(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    setVerse('');
    try {
      const response = await fetch('https://api.bibleverses.xyz/get_bible_verse', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: query }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setVerse(data.verse);
    } catch (error) {
      setError('Error: ' + error.message);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="App">
      <h1>Bible Verse Generator</h1>
      <form onSubmit={getVerse} className="form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          className="input"
        />
        <button type="submit" disabled={loading} className="button">
          {loading ? 'Loading...' : 'Get Verse'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      {verse && (
        <div className="verse-container">
          <h2>Bible Verse:</h2>
          <p>{verse}</p>
        </div>
      )}
    </div>
  );
}

export default App;

// import React, { useState, useEffect } from 'react';
// import './App.css';

// function App() {
//   const [query, setQuery] = useState('');
//   const [verse, setVerse] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   async function getVerse(e) {
//     e.preventDefault();
//     setLoading(true);
//     setError('');
//     setVerse('');

//     try {
//       const response = await fetch('https://api.bibleverses.xyz/get_bible_verse', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ query: query }),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       setVerse(data.verse);
//     } catch (error) {
//       setError('Error: ' + error.message);
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   }

//   return (
//     <div className="App">
//       <h1>Bible Verse Generator</h1>
//       <form onSubmit={getVerse}>
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           placeholder="Enter your query"
//         />
//         <button type="submit" disabled={loading}>
//           {loading ? 'Loading...' : 'Get Verse'}
//         </button>
//       </form>
//       {error && <p className="error">{error}</p>}
//       {verse && (
//         <div className="verse-container">
//           <h2>Bible Verse:</h2>
//           <p>{verse}</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default App;
